<script lang="ts">
  import TryButton from '$lib/TryButton.svelte'
  import { fade } from 'svelte/transition';

  let className: string = "";
  export { className as class }
</script>

<section class="bg-[#070811] py-32 md:py-40 {className}">
  <div class="max-w-4xl mx-auto text-center">
    <h2 
      class="text-white text-3xl md:text-4xl lg:text-5xl font-bold leading-tight mb-12"
      in:fade={{ duration: 300, delay: 150 }}
    >
      Join & connect with 1,000 others who are making an impact.
    </h2>
    
    <TryButton>It's Free - Try Now!</TryButton>
  </div>
</section>
